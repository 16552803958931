@charset "UTF-8";

//Inbox
#inbox_area {
	li {
		padding: 16px 15px 8px;
		border: 1px solid #ccc;
		background-color: #fff;
		position: relative;
		.pull-left{
			margin-right: 20px;
			text-align: center;
			font-size: 12px;
		}
		.chat_time {
			position: relative;
			right: 0;
			bottom: -20px;
			font-size: 14px;
		}
	}
}
//chat
.reserve_mentor{
	height: 40px;
    margin-top: 20px;
}
.chat_area{
	height: 400px;
	overflow: scroll;
	padding-top: 30px;
	ul li{
		width: 75%;
		padding: 20px 20px 5px;
		display: flex;
		align-items: center;
		&.mychat {justify-content: flex-end;}
	}
	.chat_img{
		margin-right: 20px;
	}
	.chat_title{
		word-wrap: break-word;
		max-width: 600px;	  
		border-radius: 10px;
		padding: 6px 10px 4px;
		background-color: #fff;
		line-break: anywhere;
	}
	.mychat .chat_title {background-color: #cfe4ef;}
	.chat_time{font-size: 0.8em; margin: 0 10px; color: #666;}
}
#chatForm{
	padding: 30px 0 10px;
	.message_write{
		margin-bottom: 10px;
	}
}

.home {
    background: #f6f7fa;
}

#adminNav {
    background: #292929;
	padding: 0;
	min-width: 202px;
}

.display-table {
    display: table;
    padding: 0;
    height: 100%;
    width: 100%;
}

.display-table-row {
    display: table-row;
    height: 100%;
}

.display-table-cell {
    display: table-cell;
    float: none;
    height: 100%;
}

.v-align {
    vertical-align: top;
}


.navi{
	a{
		border-bottom: 1px solid #929292;
		color: #fff;
		display: block;
		font-size: 17px;
		font-weight: 500;
		padding: 20px 15px;
		text-decoration: none;
		&:hover {
			background: #88711c none repeat scroll 0 0;
			padding-left: 15px;
		}
	}
	i {
		font-size: 20px;
		margin-right: 15px;
		color: #fff;
	}
	.active {
		a {
			background: #88711c;
			padding-left: 15px;
		}
	}
}
.user-dashboard {
    padding: 0 20px;
}

.user-dashboard h1 {
    color: #0e1a35;
    font-size: 26px;
    font-weight: 500;
    margin: 0;
    padding: 21px 0;
}


/*schedules*/
.my_schedules{
	thead{
		tr{
			background-color: #eaeaea;
			th{text-align: center;}
		}
	}
	tbody
	{
		background-color: #fff;
		td{
			text-align: center;
			vertical-align: middle;
		}		
	}
}


.flex_middle {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scheduleModal,#ableTimeModal,.cancelModal{
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  z-index: 999;
	textarea {
		width: 100%;
	}
	.active {
	  display: block;
	}
	.modal {
	  display: none;
	  position: relative;
	  width: 600px;
	  background-color: #FFF;
		&:active {
		  display: block;
		}
		.close {
		  font-family: "Source Sans Pro", sans-serif;
		  cursor: pointer;
		  color: #FFF;
		  width: 50px;
		  height: 50px;
		  text-align: center;
		  line-height: 50px;
		  position: absolute;
		  right: 0;
		  color: #999;
		  font-size: 40px;
			span {
			  transform: rotate(45deg);
			  display: block;
			}
		}
		.buttons {
		  width: 600px;
		  bottom: 0;
		  background-color: #FFF;
			 a {
			  width: 50%;
			  height: 50px;
			  line-height: 50px;
			  text-align: center;
			  float: left;
			  background-color: #EEE;
			  color: #4d5c6e;
			  transition: 0.3s;
			  text-transform: uppercase;
			  font-weight: bold;
				 &:hover {
				  background-color: #e1e1e1;
				}
				 &:nth-of-type(2) {
				  float: right;
				  color: #FFF;
				  background-color: #00c06d;
				}
				 &:nth-of-type(2):hover {
				  background-color: #00a75f;
				}
			}
		}
	}
}//scheduleModal

.modalbttn {
  background-color: #24252A;
  padding: 12px 25px;
  text-transform: uppercase;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
	&:hover {
	  background-color: #2b2c32;
	}
}



//ダッシュボード
#dashboard {
	.mentor-contents{
		display: none;
	}
	h3 {
		font-weight: bold;
	}
	#switch {
		margin: 20px 0 20px;
		li {
			color: lighten($baseColor,15%);
			width: 140px;
			padding: 5px;
			text-align: center;
			font-size: 12px;
			&:hover {
				cursor: pointer;
			}
			&.active {
				border-bottom: 1px solid;
				background: #fff;
			}
		}
	}
	#mypage_top {
		padding: 15px;
		a {
			border: 2px solid #c3c3c3;
			border-radius: 5px;
			padding: 15px;
			background: #fff;
			height: 140px;
			&:hover {
				border: 2px solid #fccd0c;
			}
			p {
				margin-top: 5px;
			}
			&:nth-child(2) {
				img {
					margin-top: 10px;
				}
			}
		}
	}
	#mypage_mid {
		h3 {
			text-align: left;
			margin: 10px 0;
		}
		th {
			padding: 2px 8px;
		}
	}
	#mypage_bottom {
		h3 {
			text-align: left;
		}
	}
	#mypoint {
		margin-top: 20px;
		min-width: 200px;
		h4 {
			text-align: center;
			background: #4e4d4d;
			color: #fff;
		}
		div {
			background-color: #fff;
			font-size: 1.2em;
			font-weight: bold;
			padding: 5px 0px;
			border: 1px solid #a7a7a7;
			align-items: center;
		}
		p {font-size: 14px;}
		#point {
			font-size: 1.4em;
			position: relative;
			top: 1px;
			font-weight: bold;
			margin-right: 3px;
		}
	}
	#buypoint, #registcredit {
		min-width: 200px;
		background: rgb(252, 204, 14);
		text-align: center;
		border-radius: 5px;
		margin: 10px 0;
		border: 1px solid #d4b01a;
		&:hover {
			opacity: 0.8;
		}
		a {
			padding: 5px;
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	#performance {
		border: 1px solid #a7a7a7;
		padding: 20px;
		background: #fff;
		line-height: 2;
		h4 {
			font-weight: bold;
			text-align: center;	
			margin-bottom: 10px;
		}
		dl {
			border-bottom: 1px solid #bbb;
		}
		.star {
			color: $baseColor;
		}
	}
}//dashboard

//SETTING
.setting{
	.flex-start {
		align-items: center;
		margin-top: 12px;
	}
	
	.title {
		margin-right: 15px;
		margin-left: 15px;
	}
	
	input[type=checkbox]{
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label:not(.title) {
		cursor: pointer;
		text-indent: -9999px;
		width: 55px;
		height: 25px;
		background: grey;
		display: block;
		border-radius: 25px;
		position: relative;
	}

	label:not(.title):after {
		content: '';
		position: absolute;
		top: 2.5px;
		left: 5px;
		width: 20px;
		height: 20px;
		background: #fff;
		border-radius: 20px;
		transition: 0.3s;
	}

	input:checked + label:not(.title) {
		background: #56a03e;
	}

	input:checked + label:not(.title):after {
		left: calc(100% - 5px);
		transform: translateX(-100%);
	}

	label:not(.title):active:after {
		width: 75px;
	}
}

//editprof
#editProf {
    background: #fff;
    padding: 20px;
    margin: 0 0 30px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
	.not-show {
		font-size: 8px;
		display: block;
		color: #3c3c3c;
	}
}
.sns-icon i{
	font-size: 30px;
    color: #ddd;
}
.fa-facebook-square.active{
	color: #305097;
}
.fa-twitter-square.active{
	color: #00aced;
}
.fa-github-square.active{
	color: #000;
}

//favorite
#favorite{
	.img_circle{
		margin: 2px auto;
	}
	.rating{
		margin: -8px auto 10px;
	}
}

.custom-link a {
	color: #1e9eff;	
}