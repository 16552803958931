@charset "UTF-8";
/*set*/
$baseColor : #1c6888;
$accentColor : #ec6941;//orange
$red : #ec4141;

@import "list";
@import "login";
@import "userprof";
@import "mypage";
@import "calander";
@import "payment";
@import "evaluate";
@import "become-mentor";
@import "mentor";
@import "question";
@import "page";
@import "nonresponsive";

/*mixin*/
@mixin btnset($color){
	background:  $color;
	color: white;
	&:hover{
		background: lighten($color,10%)!important;
	}
}
@mixin trimImageToCircle($size){
//    background-image: url($callimage);
    width:  $size;
    height: $size;
    border-radius: 50%;
	background-size: cover;
    background-position: center center;
}

//test-ver
#testUse{
	width: 380px;
    margin: 0 auto;
    line-height: 30px;
	border-radius: 5px;
	a{
		font-size: 1.4em;
		color: #fff;
		width: 100%;
		display: block;
		height: 40px;
		line-height: 40px;
	}
}

/*resertCss*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {line-height: 1.4;}
@media screen and (max-width:767px){
	body {
		width: 1100px;
	}
}
p{line-height: 1.6;}
ol, ul {list-style: none;}
table {border-collapse: collapse;border-spacing: 0;}
a{text-decoration: none;color: inherit;
	&:hover{text-decoration: none;}
}
a.deco{
	color: #569ad8;
	text-decoration: underline;
}
code{color: limegreen;}
.b {
	font-weight: bold;
}
.opacity {
	opacity: 0.6;
}
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td, 
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
	vertical-align: middle;
}
/*base setting*/
//color
.green_txt{
	color: #3AAA64;
}
.bread_crumb{
	margin: 20px 0 15px;
	li{display: inline-block;margin-right: 10px;}
}
.bg_gray{
	background-color: #f3f3f3;
	width: 100vw;
}
.btn.focus, .btn:focus, .btn:hover{
	color: inherit;
}
*{transition: 0.2s;}
button, input, optgroup, select, textarea{color:initial;}
.alert{margin-bottom: 0;}
select {
	height: 30px;
	background-color: #fff;
}
.badge{background: #ff4e4e;}
.white{color: white;}
.btn-orange{
	@include btnset($accentColor);
}
.btn-red{
	@include btnset(#d9534f);
}
.btn-lt-orange{
	@include btnset(lighten($accentColor,7%));
}
.btn-lt-base{
	@include btnset(lighten($baseColor,7%));
}
.btn-lt-favorite{
	@include btnset(#e0e0e0);
	color: #4c4c4c;
}
.btn-ask{
	@include btnset(#168614);
}
//btn-setting
.btn-pattern1{
	background:  lighten($accentColor,5%);
	color: white;
	padding: 10px 40px;
	display: block;
	text-align: center;
	margin-top: 40px;
	&:hover{
		background: $accentColor;
	}
}
.btn-pattern2{
	background: $baseColor;
	color: white;
	padding: 10px 40px;
	display: block;
	text-align: center;
	margin-top: 40px;
	&:hover{
		opacity: 0.8;
	}
}
.btn-lg{
	font-size: 16px;
}
/*hover*/
.hover_shadow:hover{
	box-shadow: 1px 1px 10px #999;
}
.btn:hover,.btn.focus, .btn:focus {
	color: white;
	text-decoration: none;
}
.btn-default:hover {
	color: #333;
}
.btn + .btn {
	margin-left: 15px;
}
a:hover{cursor: pointer;}
.online_mark{
	font-size: .6em;
	&:before{
		content: "";
		width: 10px;
		height: 10px;
		background: #5cb85c;
		margin: 0 0.4em 0 2em;
		display: inline-block;
		border-radius: 50%;
	}
}
.label {
	padding:0.3em 0.7em 0.3em;
}
//h1~h6-setting
h1{font-size: 2.2em;font-weight: bold;}
h2{
	font-size: 1.2em;
	font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.5;
}
h3{
	text-align: center;
}
.icon{
	display: block;
    background-repeat: no-repeat;
    height: 60px;
    background-position: 50%;
	&.pen{background-image: url('../img/pen.png');}
	&.pc{background-image: url('../img/pc.png');}
	&.star{background-image: url('../img/star.png');}
	&.rate{background-image: url('../img/rate.png');}
}
.img_circle{
	@include trimImageToCircle(150px);
}
.content_block{
	padding: 50px 0;
	.intro{
		margin-bottom: 30px;
	}
	&:nth-child(odd){
		background-color: lighten($baseColor,58%);
	}
}
#contents{
	margin: 40px auto 20px;
}

//margin-setting
.mt05{margin-top: .5em;}
.mt1{margin-top: 1em;}
.mt2{margin-top: 2em;}
.mt3{margin-top: 3em;}
.mt4{margin-top: 4em;}
.mt5{margin-top: 5em;}
.mt10{margin-top: 10em;}
.mt15{margin-top: 15em;}
.mt20{margin-top: 20em;}
.mt25{margin-top: 25em;}
.mt30{margin-top: 30em;}
.mb05{margin-bottom: .5em;}
.mb1{margin-bottom: 1em;}
.mb2{margin-bottom: 2em;}
.mb3{margin-bottom: 3em;}
.mb4{margin-bottom: 4em;}
.mb5{margin-bottom: 5em;}
.mb10{margin-bottom: 10em;}
.mb15{margin-bottom: 15em;}
.mb20{margin-bottom: 20em;}
.mb25{margin-bottom: 25em;}
.mb30{margin-bottom: 30em;}
.pd10{padding: 10px;}
.pd20{padding: 20px;}
.pd30{padding: 30px;}

.flex-start{display: flex; justify-content: flex-start; flex-wrap: wrap;}
.flex-between{display: flex; justify-content: space-between; flex-wrap: wrap;}
.flex-around{display: flex; justify-content: space-around; flex-wrap: wrap;}
.flex-center{display: flex; justify-content: center; flex-wrap: wrap;}
.align_center {
	display: flex;
    align-items: center;
    justify-content: flex-end;
}
ul.flex-start li{margin-right:5px; }
.rotate90{
	transform: rotate(270deg);
}
.dropdown-menu .dropdown-menu{
	position: absolute;
    top: -1px;
    left: 100%;
    height: auto;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
	min-height: 332px;
}
.navbar-right .dropdown-menu {
	right: -25px;
}

.dropdown-menu>li>a {
	padding: 6px 20px;
}
.btn.disabled, .btn[disabled]{
	opacity: inherit;
}
.grayscale{
		-webkit-filter: grayscale(100);
		filter: gray;
		filter: grayscale(100);
}

#questionPostErrorModal, #isPauseErrorModal{
	.errorSheetModal {
		background: #fff;
		width: 530px;
		margin: 0 auto;
		min-height: 100px;
		position: relative;
		top: 50%;
		margin-top: -100px;
		padding: 25px 40px 35px;	
	}	
}

#commentPostModal{
	.sheetModal {
		background: #fff;
		width: 530px;
		margin: 0 auto;
		min-height: 100px;
		position: relative;
		top: 35%;
		margin-top: -100px;
		padding: 25px 40px 35px;	
	}
}

/*header*/
header{
	background-color: $baseColor;
    position: relative;
    width: 100%;
	.nav>li>a{
		color: white;
        &:hover{background-color: inherit;}
	}
	.navbar{
		padding:0;
		margin-bottom: 0;
		.navbar-header a{
			font-size:26px;
			margin-top: 8px;
			font-family: "monospace";
			color: #fff!important;
			.smaller{
				font-size: .6em;
			}
		}
		.navbar-nav{
			margin-top: 6px;
			li{
				background-color: inherit;
                &.actions{ top: 5px; }
				a{
                    color: #fff;
					transition: 0;
				}
                ul>li>a{color: #000;}
			}
        }
		.navbar-right{
			float: right;
			margin: 10px;
            &__txt{
                color: #fff;
                position: relative;
                display: block;
                top: 10px;
            }
			.nav{
				margin-top: 8px;
				a,i{
				color: white;
				}
			}
			i.fa{
				color: #fff;
				font-size: 20px;
				top: 9px;
				position: relative;
				padding-bottom: 12px;
			}
			.fa.fa-question {
				font-size: 25px;
			    top: 7px;
			}
			.be-a-star{
				position: absolute;
				left: 4px;
				color: #77878e;
				font-size: 12px;
			}
		}
		.navbar-brand {
			padding: 0px 0px;
			margin: 8px 0 0 0px;
		}
	}
	#none-post{
		position: relative;
		padding: 10px 25px 10px 45px;
		&:before{
			position: absolute;
			content: '';
			background-image: url('../img/call.png');
			width: 32px;
			height: 33px;
			left: 13px;
			background-repeat: no-repeat;
			top: 5px;
			background-size: 80%;
		}
	}
	#post{
		position: relative;
		padding: 10px 25px 10px 45px;
		&:before{
			position: absolute;
			content: '';
			background-image: url('../img/call.png');
			width: 32px;
			height: 33px;
			left: 13px;
			background-repeat: no-repeat;
			top: 5px;
			background-size: 80%;
		}
	}
	#signupbox{
		position: absolute;
	}
	.confirm-poricy {
		font-size: 12px;
		margin: 7px auto 0;
		text-align: center;
		color: #666;
	}
	.postSheetForm {
		background: #fff;
		width: 780px;
		margin: 0 auto;
		padding: 30px 30px 20px;
		min-height: 100px;
		position: relative;
		top: 50%;
		margin-top: -120px;
	}
	#postSheet .postSheetForm {
		margin-top: -300px;
		padding: 25px 40px 35px;
	}
	.errorSheetModal {
		background: #fff;
		width: 530px;
		margin: 0 auto;
		padding: 30px 30px 20px;
		min-height: 100px;
		position: relative;
		top: 50%;
		margin-top: -120px;
	}
	#errorModal .errorSheetModal {
		margin-top: -100px;
		padding: 25px 40px 35px;
	}
	#postProvisional,#postTitle {
		width: 100%;
		margin: 10px auto 0;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #999;
		&:focus {
			outline: none;
		}
	}
	#postProvisional {
		margin: 10px auto;
		padding: 10px 3px 3px;
	}
	#postSheet {
		textarea,input {
			width: 100%;
		}
		input[type="radio"]{
			width: inherit;
			margin-right: 5px;
		}
		dl{margin: 20px auto;}
		dt{margin-bottom: 3px;font-weight: bold;}
	}
	#postSubmit {
//		background-color: $baseColor;
		color: #fff;
		line-height: 40px;
		border-radius: 5px;
	}
}

/*KV*/
.kv {
	text-align:center;
	background:url('../img/kv.png');
	background-size:cover;
	padding-top:160px;
	height: 570px;
	background-repeat: no-repeat;
	h1{
	  color:#fff;
	  font-weight:500;
	  margin: 30px;
	  font-size: 42px;
	}
	#questionbox{
		padding:10px;
		margin:0 auto;
		background-color:rgba(255,255,255,0.3);
		border-radius:5px;
		width:760px;
		input{
			width:630px;
			height:40px;
			margin:10px;
			padding: 10px;
		}
		.btn{
			width: 80px;
			text-align: center;
			padding: 7px;
			color: white;
			background: #238d45;
			&:hover{
				background: lighten(#238d45,5%);
			}
		}
	}
}


/*about*/
.about_service{
	img{
		width:75%;
		margin:10px 15%;
	}
	.explain{
		p{
		  margin:25px 0 30px;
		}
		.btn{
			margin: 0 auto;
			width: 170px;
		}
	}
}

/*feature*/
.features-boxed .item {
	.box {
	  padding:30px;
	  background-color:#fff;
	}
 	.icon {
	  margin-top:20px;
	  margin-bottom:35px;
	}
	.name {
	  font-weight:bold;
	}
	.description {
	  font-size:14px;
	  margin:20px 0;
	}
}

/*team*/
#topMentor{
	.img_circle{
		margin: 2px auto;
	}
}
.team-boxed .item{
	.box {
	  text-align:center;
	  padding:15px 20px 20px;
	  background-color:#fff;
	  border:1px solid #bebebe;
	}
	.name {
	  font-weight:bold;
	  margin-top:15px;
	  margin-bottom:8px;
	}
	.title {
	  font-weight:bold;
	  color:#d0d0d0;
	  letter-spacing:2px;
	  font-size:13px;
	}
	.description {
	  font-size:13px;
	  margin-top:15px;
	  margin-bottom:20px;
	}
	img {
	  max-width:160px;
	}
}

/*voice*/
.testimonials-clean .item{
	.box {
	  padding:30px;
	  background-color:#fff;
	  position:relative;
		&:after {
		  content:'';
		  position:absolute;
		  left:30px;
		  bottom:-24px;
		  width:0;
		  height:0;
		  border:15px solid transparent;
		  border-width:12px 15px;
		  border-top-color:#fff;
		}
	}
	.author {
	  margin-top:28px;
	  padding-left:25px;
	}
	.name {
	  font-weight:bold;
	  margin-bottom:2px;
	  color:inherit;
	}
	.title {
	  font-size:13px;
	  color:#9da9ae;
	}
	.description {
	  font-size:15px;
	}
	img {
	  max-width:40px;
	  float:left;
	  margin-right:12px;
	  margin-top:-5px;
	}
}
/*media*/
.media-images{
	ul.flex-between{
		align-items: center;
	}
}

/*category*/
.accent_back{
	height:243px;
	background-image:url('../img/category_back.png');
	background-position:center;
	background-size:cover;
	padding:66px;
	.row{
		width:700px;
		margin:0  auto;
		a{
			background: white;
			color: $baseColor;
			border: 2px solid white;
			padding: 3px 15px;
			display: block;
			margin: 5px 0;	
			width: 85%;
			&:hover{
				background: none;
				color: white;
			}
		}
	}
}


/*footer*/
footer {
  padding:40px 0 0;
  color:white;
  background-color:$baseColor;
	h3 {
	  margin-bottom:12px;
	  font-weight:bold;
	}
	ul {
	  line-height:1.6;
	  font-size:14px;
		& a{
		  color:inherit;
		  opacity:0.7;
			&:hover {
			  color: white;
			  opacity:0.9;
			  text-decoration: underline;
			}
		}
	}
	.copyright {
		text-align: center;
		padding: 5px 0;
		opacity: 0.8;
		font-size: 13px;
		background: darken($baseColor,20%);
	    margin-top: 30px;
	}
}

.toggle_input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.toggle_label {
  width: 55px;
  height: 25px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 40px;
  transition: 0.4s;
  box-sizing: border-box;
}

.toggle_label:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  left: 3px;
  top: 2.5px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.toggle_input:checked + .toggle_label {
  background-color: #4BD865;
}

.toggle_input:checked + .toggle_label:after {
  left: 32px;
}

.toggle_button {
  position: relative;
  width: 55px;
  height: 25px;
}

.toggle_text {
  padding-top: 3px;
  padding-left: 14px;
}

.toggle_flex {
  display: flex;
}

.fixed-reserve {
	height: 25px;
	line-height: 1.6rem;
	width: 40px;
	font-size: 0.8rem;
	padding: 0;
	margin-bottom: 5px;
}

/* admin */

#categories-wrap {
	.category-link {
		float: right;
		width: 140px;
		display: block;
		margin: 20px;
	}
	#update_ord {
		text-align: center;
	}
}

#category-wrap {
	#update_ord {
		text-align: left;
	}
	#regist_ord {
		text-align: left;
	}
	#update_category_skills {
		text-align: center;
	}
	table.ex-sort {
		box-sizing: border-box;
		border-collapse: collapse;
		border-spacing: 0;
		background-color: #f2f2f2;
		white-space: nowrap;
	}
	.ui-sortable-helper {
		td {
			background: #f6a828 !important;
			color: #ffffff !important;
			font-weight: bold;
		}
	}
	#wrap-content {
		margin: 0 auto;
		padding: 10px 20px;
		table{ 
			width: 100%; 
			th{ 
				background-color: #bee0c2;
			}
			td, th{ 
				border: 1px solid #404040; 
				height: 40px; 
				font-size: 16px;
			}
			th:nth-child(1){ 
				width:40px; 
				padding: 8px;
			}
			th:nth-child(2){ 
				width:200px; 
				padding: 8px;
			}
			tbody{
				border-bottom: 1px #c0c0c0 solid;
				height: 400px;
				overflow-y: scroll;
				td { 
					background-color: #ffffff;
				}
				td:nth-child(1) { 
					width:40px; 
					min-width:40px; 
					text-align: center; 
					padding: 8px;
				}
				td:nth-child(2) { 
					width:200px; 
					min-width:100px; 
					text-align: center; 
					padding: 8px;
				}				
			}
		}
	}
	#left-band{
		padding: 10px 30px;
		height: 580px;
		overflow: scroll;
	}
	#right-band{
		padding: 10px 30px;
		height: 580px;
		overflow: scroll;
	}
}

#messages_list {
	td.truncate-text {
		white-space: pre-line;
		text-align: left!important;	
	}
	td.button-wrap {
		display: flex;
		border-bottom: none;
	}
	.content-annotation {
		font-size: 0.8rem;
	}
}

#messages_send {
	#test_mail {
		width: 200px;
	}
	#send_user {
		width: 150px;
	}
}

#notifications_list {
	td.truncate-text {
		white-space: pre-line;
		text-align: left!important;	
	}
	td.button-wrap {
		display: flex;
		border-bottom: none;
	}
}

#question-wrap {
	.user-area {
		padding-left: 5px;
		position: relative;
		width: 300px;
	}
	.user-name-area {
		position: absolute;
		top: 5px;
		left: 40px;
	}
	.col-md-3 {
        margin-bottom: 4px;
    }
	.col-md-8 {
        margin-bottom: 4px;
	}
}

#questions-wrap {
	td.truncate-text {
		white-space: pre-line;
		text-align: left!important;
	}
	.question_click {
		cursor: pointer;
	}
	.user-area {
		padding-left: 5px;
		position: relative;
	}
	.user-name-area {
		position: absolute;
		top: 5px;
		left: 40px;
	}
}

#schedules-wrap {
	.user_schedule_click {
        cursor: pointer;
    }
}

#schedule-wrap {
	.col-md-3 {
        margin-bottom: 4px;
    }
	.col-md-8 {
        margin-bottom: 4px;
	}
}

#skills-wrap {
	#regist_ord {
		text-align: left;
	}
	#update_popularity_skills {
		text-align: center;
	}
	table.ex-sort{
		box-sizing: border-box;
		border-collapse: collapse;
		border-spacing: 0;
		background-color: #f2f2f2;
		white-space: nowrap;
	}
	.ui-sortable-helper {
		td{
			background: #f6a828 !important;
			color: #ffffff !important;
			font-weight: bold;
		}
	}
	#wrap-content{
		margin: 0 auto;
		padding: 10px 20px;
		table{ 
			width: 100%; 
			th { 
				background-color: #bee0c2; 
			}
			th:nth-child(1){ 
				width:40px; 
				padding: 8px; 
			}
			th:nth-child(2){ 
				width:200px; 
				padding: 8px; 
			}
			td, th{ 
				border: 1px solid #404040; height: 40px; font-size: 16px; 
			}
			tbody{
				border-bottom: 1px #c0c0c0 solid;
				height: 400px;
				overflow-y: scroll;
				td{ 
					background-color: #ffffff; 
				}
				td:nth-child(1){ 
					width:40px; 
					min-width:40px; 
					text-align: center; 
					padding: 8px; 
				}
				td:nth-child(2){ 
					width:200px; 
					min-width:100px; 
					text-align: center; 
					padding: 8px; 
				}	
			}
		}
	}
	#left-band{
		padding: 10px 30px;
		height: 580px;
		overflow: scroll;
	}
	#right-band{
		padding: 10px 30px;
		height: 580px;
		overflow: scroll;
	}
}

.csv-export-btn {
	float: right;
}